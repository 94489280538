import { Table, TableCell, TableRow } from "@skf-internal/ui-components-react";

export interface ICustomTable {
    dataRows: any[];
    headerCells: any[];
    setSortingValuesCallback: any; 
  }
  
  export default function CustomTable (props: ICustomTable) {
    const {dataRows, headerCells, setSortingValuesCallback} = props;
    
    const sortableHead = () => {
        let headers:TableCell[] = [];
        
        headerCells.forEach(field => {
          if (field.field != null){
            headers.push(createColumnConfig(field));
          } else {
            headers.push(createColumnConfigNoSort(field));
          }
        });
        return [{ cells: headers}] as TableRow[]; 
      }
    
      
      function createColumnConfig(fieldInfo:any) {
        return {
          children: fieldInfo.label,
          scope: "col",
          width: fieldInfo.width?fieldInfo.width + "px":"",
          className: "Sort-" + fieldInfo.order,
          onClick: () => {
            tableSort(fieldInfo.field, headerCells);
          },
        } as TableCell;
      }
      function createColumnConfigNoSort(fieldInfo:any) {
        return {
          children: fieldInfo.label,
          scope: "col",
          width: fieldInfo.width?fieldInfo.width + "px":"",
          className: fieldInfo.className??""
        } as TableCell;
      }

      function clearSort(exclude: string, headers: any[]) {
        headers.forEach(fieldInfo => {
          if (fieldInfo.field && exclude !== fieldInfo.label){
            fieldInfo.setOrderCallback("undefined");
          }
        })
      }
      function toggleSearch(currentValue: string) {
        var newValue: "undefined" | "ascending" | "descending" = "undefined";
        switch (currentValue) {
          case "undefined":
            newValue = "ascending";
            break;
          case "ascending":
            newValue = "descending";
            break;
          case "descending":
            newValue = "undefined";
            break;
        }
    
        return newValue;
      }
      const tableSort = (fieldName: string, headers: any[]) => {
        var order = undefined;
        let fieldInfo:any = headers.find(f=> f.field == fieldName); 
        order = toggleSearch(fieldInfo.order); 
        fieldInfo.setOrderCallback(order); 
        clearSort(fieldInfo.label, headers);
    
        console.log("Sorting value " + fieldName + " "+ order);
        setSortingValuesCallback ([fieldName, order + ""]);
      };
     
 
return  <Table
feBody={dataRows}
feHead={sortableHead()}
feCompact
feScrollable={true}
/>

  } 