import { useState } from "react";
import {
  handleApplicationApprovalRequests,
  handleGlobalRoleApprovalRequests,
} from "./approvalsAPI";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  approvalsApplicationPendingAsync,
  approvalsApplicationResolvedAsync,
  approvalsGlobalRolePendingAsync,
  approvalsGlobalRoleResolvedAsync,
} from "./approvalsSlice";
import { selectAuth } from "../sso/ssoSlice";
import {
  Button,
  Checkbox,
  Dialog,
  Flexbox,
  Spacer,
  Text,
  TextAreaField,
  useToast,
} from "@skf-internal/ui-components-react";

export interface IApprovalDialogProps {
  ticket: any;
  approverUserId: string;
  isOpen: boolean;
  dialogueType: string;
  closeCallback: any;
  reloadCallback: any;
}
export interface IPayload {
  requestId: string;
  approverUserId: string;
  status: "Approved" | "Denied";
  reason: string;
}

export default function ApprovalDialog(props: IApprovalDialogProps) {
  const { ticket, isOpen, dialogueType, closeCallback, reloadCallback } = props;
  const dispatch = useAppDispatch();
  const auth = useAppSelector(selectAuth);

  const [reason, setReason] = useState("");
  const [declineHint, setDecineHint] = useState("");
  const [declineError, setDeclineError] = useState<"error" | undefined>(
    undefined
  );
  const [disable, setDisable] = useState(false);
  const { addToast } = useToast();

  const handleAppRoleActions = (payload: any) => {
    handleApplicationApprovalRequests(payload.requestId, payload)
      .then((res) => {
        addToast({
          children: "Ticket updated",
          feSeverity: "success",
        });
        reloadCallback();
      })
      .catch((error) => {
        console.log("Role approval failed " + error);
        dispatch(
          approvalsApplicationPendingAsync({ userId: auth.idTokenClaims.sub })
        );
        dispatch(
          approvalsGlobalRoleResolvedAsync({ userId: auth.idTokenClaims.sub })
        );
      });
  };
  const handleGlobalRoleActions = (payload: any) => {
    handleGlobalRoleApprovalRequests(payload)
      .then((res) => {
        addToast({
          children: "Ticket updated",
          feSeverity: "success",
        });
        reloadCallback();
      })
      .catch((error) => {
        console.log("Role approval failed " + error);
        dispatch(
          approvalsApplicationPendingAsync({ userId: auth.idTokenClaims.sub })
        );
        dispatch(
          approvalsGlobalRoleResolvedAsync({ userId: auth.idTokenClaims.sub })
        );
      });
  };

  const handleOnConfirm = () => {
    if (ticket.applicationId != null) {
      if (dialogueType === "Decline") {
        handleAppRoleDecline();
      } else {
        handleAppRoleAccept();
      }
    } else {
      if (dialogueType === "Decline") {
        handleGlobalRoleDecline();
      } else {
        handleGlobalRoleAccept();
      }
    }
    closeCallback(false);
    setReason("");
    setDecineHint("500 max");
  };
  const handleGlobalRoleDecline = () => {
    if (reason.length > 500) {
      setReason(reason.substring(0, 500));
    }

    const payload: IPayload = {
      requestId: ticket.requestId,
      approverUserId: auth.idTokenClaims.sub,
      status: "Denied",
      reason: reason,
    };
    handleGlobalRoleActions(payload);
  };

  const handleGlobalRoleAccept = () => {
    if (reason.length > 500) {
      setReason(reason.substring(0, 500));
    }
    const payload: IPayload = {
      requestId: ticket.requestId,
      approverUserId: auth.idTokenClaims.sub,
      status: "Approved",
      reason: reason,
    };
    handleGlobalRoleActions(payload);
  };
  const handleAppRoleDecline = () => {
    if (reason.length > 500) {
      setReason(reason.substring(0, 500));
    }

    const payload: IPayload = {
      requestId: ticket.requestId,
      approverUserId: auth.idTokenClaims.sub,
      status: "Denied",
      reason: reason,
    };
    handleAppRoleActions(payload);
  };
  const handleAppRoleAccept = () => {
    if (reason.length > 500) {
      setReason(reason.substring(0, 500));
    }
    const payload: IPayload = {
      requestId: ticket.requestId,
      approverUserId: auth.idTokenClaims.sub,
      status: "Approved",
      reason: reason,
    };
    handleAppRoleActions(payload);
  };

  const isButtonDisabled = () => {
    let res: boolean | undefined;
    res = false;
    if (disable) {
      res = true;
    }
    return res;
  };
  function actionText() {
    if (dialogueType === "Decline") {
      return "decline";
    } else {
      return "approve";
    }
  }

  return (
    <>
      <Dialog
        feInterruptive
        feTitle={dialogueType + " request"}
        open={isOpen}
        cssWidth={"54rem"}
        cssHeight={"28rem"}
      >
        <div className="approvalDialog">
          <Text>
            You are currently {actionText} the approval request for "
            {ticket?.requestedRole}" for&nbsp;
            <strong>
              {ticket?.firstName} {ticket?.lastName} ({ticket?.email}).
            </strong>
          </Text>
          <Spacer />
          <Checkbox
            disabled
            defaultChecked
            feLabel="The user will be notified about the decision."
            feSize="sm"
          />
          <Spacer feSpacing="md" />
          <TextAreaField
            feLabel="Personal message (optional)"
            feHint={declineHint}
            feSeverity={declineError}
            placeholder="Add a personal message to the user for your decision."
            onChange={(_, value) => {
              setReason(value);

              if (value != undefined && value != "") {
                setDecineHint(value.length + " / 500 max");
                if (value.length > 500) {
                  setDeclineError("error");
                } else {
                  setDeclineError(undefined);
                }
              } else {
                setDecineHint("500 max");
              }
              setDisable(declineError != undefined);
            }}
            value={reason}
          />
          <Spacer />
          <Flexbox
            feGap="md"
            feJustifyContent="flex-end"
            className="modalButtons"
          >
            <Button
              feType="secondary"
              onClick={() => {
                closeCallback(false);
                setReason("");
                setDecineHint("500 max");
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => handleOnConfirm()}
              disabled={isButtonDisabled()}
            >
              Confirm
            </Button>
          </Flexbox>
        </div>
      </Dialog>
    </>
  );
}
