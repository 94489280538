import {
  Table,
  TableRow,
  Loader,
  Tag,
  EmptyState,
  Dialog,
  Text,
  Button,
  Spacer,
  TextAreaField,
  Flexbox,
  useToast,
  Breadcrumbs,
  GridItem,
  BreadcrumbsItem,
  Grid,
  Switch,
  Search,
  Pagination,
} from "@skf-internal/ui-components-react";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import ViewUserLink from "../global-roles/ViewUserLink";
import {
  applicationUserAsync,
  countriesAsync,
  domainsAsync,
  IFetchCountries,
  IFetchDomains,
  IRoleItem,
  selectApplicationRoleUserCountries,
  selectApplicationRoleUserDomains,
  selectRoles,
  selectUsers,
  selectUserStatus,
} from "./applicationDetailsSlice";
import { detachAppRoleFromUser } from "./applicationDetailsAPI";
import { IApplication } from "../applications/Applications";
import { useNavigate, useParams } from "react-router-dom";
import { selectAuth } from "../sso/ssoSlice";
import { selectApps } from "../applications/applicationsSlice";
import { useDebounce } from "../common/useComplexUserSearch";
import { getCountryName, getFlag } from "../../Utils/getflag";
import ApplicationRolesUserFilter from "./ApplicationRoleUserFilter";
import { IDateFilterValue } from "../common/DateFilter";
import RolesUserFilterTags, {
  IRolesUserFilter,
} from "../common/RoleUsersFilterTags";
import { displayDate } from "../common/Common";

type roleDetail = {
  roleId: string | number;
  roleName?: string;
  userId: string;
  applicationId: string;
  adminId: string;
  reason?: string;
  removeRoleUserName?: string;
};
export default function AppRoleUsers() {
  const { appId = "", roleId = "" } = useParams();

  const dispatch = useAppDispatch();
  const users = useAppSelector(selectUsers);
  const status = useAppSelector(selectUserStatus);
  const { addToast } = useToast();

  const [showModal, setShowModal] = useState(false);
  const [removeRoleSelect, setRemoveRoleSelect] = useState<null | roleDetail>(
    null
  );
  const [reason, setReason] = useState("");
  const [ticket] = useState("");
  const [cancelButtonDisable, setCancelButtonDisable] = useState(false);
  const [breadcrumbs, setBreadcrumbs] = useState<BreadcrumbsItem[]>([]);
  const auth = useAppSelector(selectAuth);
  const apps = useAppSelector(selectApps);
  const roles = useAppSelector(selectRoles);

  const domains = useAppSelector(selectApplicationRoleUserDomains);
  const countries = useAppSelector(selectApplicationRoleUserCountries);
  const initialApp = {} as IApplication;
  const initialRole = {} as IRoleItem;
  const [app, setApp] = useState<IApplication>(initialApp);
  const [role, setRole] = useState<IRoleItem>(initialRole);
  const [quickFilter, setQuickFilter] = useState("");
  const [includeInternalUser, setIncludeInternalUser] = useState(false);
  const [initInProgress, setInitInProgress] = useState(true);

  const navigate = useNavigate();
  const gotoRoute = (route: string) => {
    navigate(route);
  };
  const [lastLoginFilter, setLastLoginFilter] = useState<IDateFilterValue>();
  const [filteredDomains, setFilteredDomains] = useState<string[]>([]);
  const [filteredCountries, setFilteredCountries] = useState<string[]>([]);
  const [createdDateFilter, setCreatedDateFilter] =
    useState<IDateFilterValue>();
  const [sortingValues, setSortingValues] = useState(["", ""]);
  const [page, setPage] = useState(1);

  //Sorting
  const [sortingFirstName, setSortingFirstName] = useState<
    "undefined" | "ascending" | "descending"
  >("undefined");
  const [sortingLastName, setSortingLastName] = useState<
    "undefined" | "ascending" | "descending"
  >("undefined");
  const [sortingEmail, setSortingEmail] = useState<
    "undefined" | "ascending" | "descending"
  >("undefined");
  const [sortingCompany, setSortingCompany] = useState<
    "undefined" | "ascending" | "descending"
  >("undefined");
  const [sortingCountry, setSortingCountry] = useState<
    "undefined" | "ascending" | "descending"
  >("undefined");
  const [sortingLastLogin, setSortingLastLogin] = useState<
    "undefined" | "ascending" | "descending"
  >("undefined");
  const [sortingCreationDate, setSortingCreationDate] = useState<
    "undefined" | "ascending" | "descending"
  >("undefined");

  //----------------------removing role form user function------------------

  const removeRolefromUsers = async () => {
    if (removeRoleSelect !== null) {
      setCancelButtonDisable(true);
      try {
        const { userId, applicationId, roleId, adminId } = removeRoleSelect;
        let reasonText = reason + "Refering Snow Ticket : " + ticket;

        const response = await detachAppRoleFromUser(
          userId,
          appId + "",
          roleId + "",
          { adminId: adminId, reason: reasonText }
        );
        if (response.status === 200) {
          addToast({
            children: "Role removed successfully",
            feSeverity: "success",
          });
        } else {
          addToast({
            children:
              "Something went wrong. Please contact system administration",
            feSeverity: "error",
          });
        }

        //Here I need to implement the toast message.
      } catch (error) {
        console.log(error);
        addToast({
          children:
            "Something went wrong. Please contact system administration",
          feSeverity: "error",
        });
      } finally {
        setCancelButtonDisable(false);
        setRemoveRoleSelect(null);
        loadUsers();
        setShowModal(false);
      }
    }
  };
  // ------------------------------------------------------------------------

  const debounceFilter = () => {
    //filter logic will go here and a new dispatch function is going to be added

    console.log(quickFilter);
    if (quickFilter.length > 2 || quickFilter === "") {
      loadUsers();
    }
  };
  useEffect(() => {
    if (domains.total > 0) {
      applicationRoleUserFilters.availableDomains = domains.data;
    }
  }, [domains]);
  useEffect(() => {
    if (countries.total > 0) {
      applicationRoleUserFilters.availableCountries = countries.data;
    }
  }, [countries]);
  const loadUsers = () => {
    dispatch(
      applicationUserAsync({
        adminId: auth.idTokenClaims.sub,
        applicationId: appId,
        roleId: roleId,
        order: sortingValues[1] == "descending" ? "desc" : "asc",
        quickFilter: quickFilter,
        excludeEmailDomain: includeInternalUser ? "" : "skf.com",
        lastLoginFilter: lastLoginFilter,
        createdDateFilter: createdDateFilter,
        filterByDomains: filteredDomains,
        filterByCountries: filteredCountries, 
        queryPage: page,
        itemsPerPage: itemsPerPage(),
        sortByField: sortingValues[0],
      })
    );
  };
  const itemsPerPage = () => {
    console.log(
      "window.outerHeight " +
        window.outerHeight +
        " window.innerHeight " +
        window.innerHeight
    );
    const scalingFactor = window.devicePixelRatio;
    console.log(`Scaling factor for current window is: ${scalingFactor}`);
    let cellHeight = 48;

    let height = scalingFactor * (window.innerHeight - 325);
    return Math.floor(height / cellHeight);
  };
  const filterUsers = () => {
    if (!initInProgress) {
      console.log("fetching user by updated filter:  " + quickFilter);
      try {
        debouncedOnChange();
      } catch (err) {
        console.error(err);
      }
    }
  };
  const debouncedOnChange = useDebounce(debounceFilter, 1000);

  const debouncedOnFilterChange = useDebounce(filterUsers, 900);
  useEffect(() => {
    if (!initInProgress) {
      if (quickFilter?.length > 2 || quickFilter?.length == 0) {
        setPage(1);
        debouncedOnFilterChange();
      }
    }
  }, [quickFilter]);

  const loadAvailableDomains = () => {
    if (roleId != null) {
      let fetchDomains: IFetchDomains = {
        roleId: roleId,
        adminId: auth.idTokenClaims.sub,
        applicationId: appId,
        level: "",
        queryPage: 1,
        itemsPerPage: 10000,
        quickFilter: quickFilter,
        excludeEmailDomain: includeInternalUser ? "" : "skf.com",
        lastLoginFilter: lastLoginFilter,
        createdDateFilter: createdDateFilter,
      };
      dispatch(domainsAsync(fetchDomains));
    }
  };
  const loadAvailableCountries = () => {
    if (roleId != null) {
      let fetchContries: IFetchCountries = {
        roleId: roleId,
        adminId: auth.idTokenClaims.sub,
        applicationId: appId,
        level: "",
        queryPage: 1,
        itemsPerPage: 10000,
        quickFilter: quickFilter,
        excludeEmailDomain: includeInternalUser ? "" : "skf.com",
        lastLoginFilter: lastLoginFilter,
        createdDateFilter: createdDateFilter,
      };
      dispatch(countriesAsync(fetchContries));
    }
  };
  useEffect(() => {
    if (!initInProgress) {
      debouncedOnChange();
    }
    applicationRoleUserFilters.includeInternalUser = includeInternalUser;
  }, [quickFilter, sortingValues, includeInternalUser, page]);

  useEffect(() => {
    //initial load data
    loadUsers();
    loadAvailableDomains();
    loadAvailableCountries();
    let filterApps = apps.filter((p) => p.applicationId == appId);
    if (filterApps.length > 0) {
      setApp(filterApps[0]);
    }

    let filteredRoles = roles.filter((p) => p.roleId === parseInt(roleId + ""));
    if (filteredRoles.length > 0) {
      setRole(filteredRoles[0]);
    }
    setTimeout(function () {
      setInitInProgress(false);
    }, 100);
    setBreadc();
  }, []);
  useEffect(() => {
    if (status === "failed") {
      addToast({
        children: "Something went wrong!! Pleasse try again later",
        feSeverity: "error",
      });
    }
  }, [status]);

  useEffect(() => {
    setBreadc();
  }, [app, role]);

  const setBreadc = () => {
    let bc = [
      {
        label: "Application",
        href: "/app",
        onClick: (e, route) => gotoRoute(route),
      },
      {
        label: `${app.applicationName}`,
        href: "/app/" + app?.applicationId,
        onClick: (e, route) => gotoRoute(route),
      },
      {
        label: `${role.roleName}`,
        href: "/app/" + app?.applicationId + "/roles/" + roleId + "/users",
        onClick: (e) => {
          e.stopPropagation();
        },
      },
    ] as BreadcrumbsItem[];

    setBreadcrumbs(bc);
  };
  // -------------------Need to add in common js --------------------------
  

  const [applicationRoleUserFilters, setApplicationRolesUserFilter] =
    useState<IRolesUserFilter>({
      includeInternalUser: includeInternalUser,
      creatonDateFilter: {} as IDateFilterValue,
      lastLoginDateFilter: {} as IDateFilterValue,
      filteredDomains: [],
      filteredCountries: [],
      availableDomains: [],
      availableCountries: []
    } as IRolesUserFilter);

  const applyFilter = () => {
    //setIncludeInternalUser(applicationRoleUserFilters.includeInternalUser);
    setLastLoginFilter(applicationRoleUserFilters.lastLoginDateFilter);
    setCreatedDateFilter(applicationRoleUserFilters.creatonDateFilter);
    setFilteredDomains(applicationRoleUserFilters.filteredDomains);
    setFilteredCountries(applicationRoleUserFilters.filteredCountries);
    debouncedOnChange();
  };
  // ----------------------------------------------------------------------
  function splitRolesToTags(user: any) {
    let roles: any[] = user.applicationRoles;
    let i: number = 0;
    let res: any;
    let count: number = 0;
    let roleIdNbr = Number(roleId);
    let rolesToolTip = roles
      .map((role) => {
        return role.roleName;
      })
      .join(",\n ");

    return (
      <>
        <div style={{ whiteSpace: "nowrap" }}>
          {roles.filter((p) => p.roleId == roleIdNbr).length > 0 && (
            <Tag
              feIcon="edit"
              feClosable={{
                title: "Remove role from user",
                onClick: () => {
                  setShowModal(true),
                    setRemoveRoleSelect({
                      adminId: auth?.idTokenClaims?.sub,
                      userId: user.id,
                      applicationId: appId + "",
                      roleId: role.roleId,
                      roleName: role.roleName,
                      removeRoleUserName: user.email,
                    });
                },
              }}
              feSize="md"
              feType="outlined"
            >
              {role.roleName}
            </Tag>
          )}
          {roles.length > 1 && (
            <span
              className="additionalRoles"
              aria-label={"User has this roles:\n\n" + rolesToolTip}
            >
              <span className="counter"> +{roles.length - 1}</span>
            </span>
          )}
        </div>
      </>
    );
  }
  function clearSort(exclude: string) {
    if (exclude !== "firstName") {
      setSortingFirstName("undefined");
    }
    if (exclude !== "lastName") {
      setSortingLastName("undefined");
    }
    if (exclude !== "email") {
      setSortingEmail("undefined");
    }
    if (exclude !== "company") {
      setSortingCompany("undefined");
    }
    if (exclude !== "country") {
      setSortingCountry("undefined");
    }
    if (exclude !== "lastLogin") {
      setSortingLastLogin("undefined");
    }
    if (exclude !== "creationDate") {
      setSortingCreationDate("undefined");
    }
  }
  function toggleSearch(currentValue: string) {
    var newValue: "undefined" | "ascending" | "descending" = "undefined";
    switch (currentValue) {
      case "undefined":
        newValue = "ascending";
        break;
      case "ascending":
        newValue = "descending";
        break;
      case "descending":
        newValue = "undefined";
        break;
    }

    return newValue;
  }

  const sort = (field: string) => {
    var order = undefined;
    switch (field) {
      case "firstName": {
        order = toggleSearch(sortingFirstName);
        setSortingFirstName(order);
        break;
      }
      case "lastName": {
        order = toggleSearch(sortingLastName);
        setSortingLastName(order);
        break;
      }
      case "email": {
        order = toggleSearch(sortingEmail);
        setSortingEmail(order);
        break;
      }
      case "company": {
        order = toggleSearch(sortingCompany);
        setSortingCompany(order);
        break;
      }
      case "country": {
        order = toggleSearch(sortingCountry);
        setSortingCountry(order);
        break;
      }
      case "lastLogin": {
        order = toggleSearch(sortingLastLogin);
        setSortingLastLogin(order);
        break;
      }
      case "creationDate": {
        order = toggleSearch(sortingCreationDate);
        setSortingCreationDate(order);
        break;
      }
    }
    clearSort(field);

    setSortingValues([field, order + ""]);
  };
  const sortableHead: TableRow[] = [
    {
      cells: [
        {
          children: "First name",
          scope: "col",
          width: "150px",
          className: "Sort-" + sortingFirstName,
          onClick: () => {
            sort("firstName");
          },
        },
        {
          children: "Last name",
          scope: "col",
          width: "150px",
          className: "Sort-" + sortingLastName,
          onClick: () => {
            sort("lastName");
          },
        },
        {
          children: "Email",
          scope: "col",
          width: "150px",
          className: "Sort-" + sortingEmail,
          onClick: () => {
            sort("email");
          },
        },
        {
          children: "Country",
          scope: "col",
          width: "90px",
          className: "Sort-" + sortingCountry,
          onClick: () => {
            sort("country");
          },
        },
        {
          children: "Company",
          scope: "col",
          width: "100px",
          className: "Sort-" + sortingCompany,
          onClick: () => {
            sort("company");
          },
        },
        {
          children: "Application role",
          scope: "col",
          width: "400px",
          className: "TagCell",
        },
        {
          children: "Last login",
          scope: "col",
          className: "Sort-" + sortingLastLogin,
          onClick: () => {
            sort("lastLogin");
          },
        },
        {
          children: "Creation date",
          scope: "col",
          className: "Sort-" + sortingCreationDate,
          onClick: () => {
            sort("creationDate");
          },
        },
        { children: "More", scope: "col", width: "150px" },
      ],
    },
  ];
  const rows = users?.data.map((item: any) => ({
    cells: [
      {
        children: item.givenName,
      },
      {
        children: item.surname,
      },
      {
        children: item.email,
      },
      {
        children: (
          <div className="cellCountry" title={item.countryName}>
            {item.countryIso?.length > 1 && (
              <img
                loading="lazy"
                height="14"
                srcSet={`https://flagcdn.com/w40/${getFlag(
                  item.countryIso
                )}.png`}
                src={`https://flagcdn.com/w40/${getFlag(item.countryIso)}.png`}
                alt={getCountryName(item.countryIso)}
                title={getCountryName(item.countryIso)}
                className="flag"
              />
            )}
            <span>{getCountryName(item.countryIso)}</span>
          </div>
        ),
      },
      {
        children: !!item.company === true ? item.company : "  ",
      },
      {
        children: <>{splitRolesToTags(item)}</>,
        className: "TagCell",
      },
      {
        children: displayDate(item.lastLogon),
      },
      {
        children: displayDate(item.createdAt),
      },
      {
        children: (
          <div style={{ width: "6.5rem" }}>
            {" "}
            <ViewUserLink user={item} />{" "}
          </div>
        ),
      },
    ],
  }));

  return (
    <>
      <Spacer feSpacing="md" />
      <Breadcrumbs
        feItems={breadcrumbs as BreadcrumbsItem[]}
        feSize="sm"
        feType="interactive"
        className="breadcrumbs"
      />
      <Spacer />

      <Grid className="gridHead">
        <GridItem
          feColspan={{
            desktop: 12,
            mobile: 12,
            tablet: 12,
          }}
        >
          <div className="contentArea">
            <div style={{ width: "100%" }}>
              <Flexbox feGap="md" feJustifyContent="space-between">
                <div style={{ width: "60rem" }}>
                  <Search
                    className="userSearch"
                    feLabel=""
                    feResetButton={{
                      "aria-label": "Rensa",
                      onClick: () => setQuickFilter(""),
                    }}
                    placeholder={
                      "Search by first name, last name, company, country and/or e-mail"
                    }
                    onChange={(_, value) => {
                      setQuickFilter(value);
                    }}
                    onKeyDown={(event: any) => {
                      if (event.key === "Enter") {
                        // handleUserInputChange(quickFilter);
                      }
                    }}
                    value={quickFilter}
                    // disabled={searchStatus !== "idle"}
                  />
                  <div className="filterTag">
                    <RolesUserFilterTags
                      setIncludeInternalUser={setIncludeInternalUser}
                      filterData={applicationRoleUserFilters}
                    />
                  </div>
                </div>
                <ApplicationRolesUserFilter
                  filterData={applicationRoleUserFilters}
                  updateOnApply={applyFilter}
                  setIncludeInternal={setIncludeInternalUser}
                />
              </Flexbox>

              {status === "loading" ? (
                <div style={{ margin: "auto" }}>
                  {" "}
                  <Loader />{" "}
                </div>
              ) : (
                <Table
                  feScrollable={true}
                  feBody={rows}
                  feHead={sortableHead}
                />
              )}
              {users.total > users.page_size && (
                <Flexbox feJustifyContent="flex-end">
                  <Pagination
                    feControls={{
                      next: {
                        "aria-label": "Next page",
                      },
                      prev: {
                        "aria-label": "Previous page",
                      },
                    }}
                    feItemsPerPage={users.page_size}
                    feOnPageChange={(page: number) => {
                      setPage(page);
                    }}
                    fePage={users.page}
                    feTotal={users.total}
                  />
                </Flexbox>
              )}
              {status !== "loading" &&
                users.data.length < 1 &&
                !initInProgress && (
                  <>
                    <EmptyState
                      feHeading={{
                        children: "No Users found",
                      }}
                      feText="No Users found on this role"
                    >
                      Descriptive title message
                    </EmptyState>
                  </>
                )}
            </div>
            <Dialog
              open={showModal}
              feInterruptive
              feTitle="Remove role from user"
              cssWidth={"35rem"}
              cssHeight={"23.3rem"}
            >
              <Text>
                You are currently removing the role{" "}
                <b>{removeRoleSelect?.roleName}</b> from user{" "}
                <b> {removeRoleSelect?.removeRoleUserName}</b> which will
                restrict access to certain features.
              </Text>
              <Spacer feSpacing="md" />
              <TextAreaField
                feLabel="Reason (for internal use)"
                onChange={(_, value) => {
                  setReason(value);
                }}
              />
              <Spacer feSpacing="md"></Spacer>
              <Flexbox feGap="md" feJustifyContent="flex-end">
                <Button
                  disabled={cancelButtonDisable}
                  feType="secondary"
                  onClick={() => {
                    setShowModal(false);
                  }}
                >
                  Cancel
                </Button>
                <Button onClick={() => removeRolefromUsers()}>Remove</Button>
              </Flexbox>
            </Dialog>
          </div>
        </GridItem>
      </Grid>
    </>
  );
}
